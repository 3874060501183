<template>
  <div class="admin-page-title">Редактирование блока главное</div>
  <div class="admin-page-block-desc">
    <label>Заголовок</label>
    <input type="text"
           v-bind:value="block.page_title"
           @input="block.page_title = $event.target.value"
    >
    <label>Описание</label>
    <textarea name="" id="" cols="20" rows="5"
              v-bind:value="block.page_desc"
              @input="block.page_desc = $event.target.value"></textarea>
    <div class="btn-save" @click="saveContent">Сохранить</div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Видео блок
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isVideoBlock = !isVideoBlock">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isVideoBlock" style="display: block;">
      <div class="video-preview">
        <video controls v-if="new_video == null" :src="'https://vrizion.ru/video/' + video_url" style="z-index: 1000;" id="index-video"></video>
        <video controls v-if="new_video !== null" :src="new_video" style="z-index: 1000;" id="index-video"></video>
      </div>
      <div class="image-loader-block" style="width: 580px;">
        <input accept="video/*" multiple type="file"
               @change="previewVideo">
        <svg viewBox="0 0 24 24" style="height: 32px; width: 32px;">
          <path fill="currentColor" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z"></path>
        </svg>
      </div>
      <div class="btn-save" @click="saveVideo">Сохранить</div>
<!--      <div class="admin-card-game" v-for="car in carousel_games" :key="car" @click="openModal(car)" :style="{ backgroundImage: 'url(https://vrizion.ru/images/games/carousel/' + car.image + ')' }">{{car.title}}</div>-->
    </div>
  </div>
</template>

<script>

import axios from "axios";
export default {
  name: "AdminHome",
  data(){
    return{
      block_id: 1,
      new_video: null,
      video_url: '',
      video: null,
      video_name: '',
      video_file: null,
      block: {
        page_title: "",
        page_desc: ""
      },
      isVideoBlock: false

    }
  },
  methods:{
    previewVideo(event) {
      const file = event.target.files[0];
      this.new_video = URL.createObjectURL(file);
      this.video_name = file.name;
      this.video_file = event.target.files[0];
    },
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.block.page_title = response.data[0].title
            this.block.page_desc = response.data[0].description
            // console.log(response.data)

          })
          .catch(() => {

          });
    },
    getVideo(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_video_admin')
          .then(response => {
            this.video_url = response.data
            // console.log(response.data)

          })
          .catch(() => {

          });
    },

    saveContent(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_page_texts', {
            block: this.block, page_id: this.block_id
          })
          .then(response => {
            this.getText()
            console.log(response)

          })
          .catch(() => {

          });
    },
    saveVideo(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_video_home', {
            video_name: this.video_name
          })
          .then(response => {
            this.getVideo()
            console.log(response)

          })
          .catch(() => {

          });
      if(this.video_file !== null){
        axios
            .post('https://vrizion.ru/server/home_video_loader.php', {file: this.video_file}, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {

              console.log(response.data)
              this.video = null
              this.video_name = ''

            })
            .catch(() => {

            });
      }

    }
  },
  mounted() {
    this.getText()
    this.getVideo()

  }
}
</script>

<style scoped>
.image-loader-block{
  width: 241px;
  height: 60px;
  background-color: #f6f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dbe0e5;
  cursor: pointer;
  margin: 1rem 0;
  transition: 0.5s;
}
.image-loader-block:hover{
  background-color: #03e882;
  color: #059f59;
}

.image-loader-block svg{
  position: absolute;
}
.image-loader-block input[type=file]{
  position: inherit;
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.video-preview{
  width: 580px;
  height: 370px;
}
#index-video{
  height: 100%;
  width: 100%;
  z-index: -100;
  right: 0;
  bottom: 0;
  background: no-repeat center center;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
}
</style>