<template>
  <div class="overlay" id="overlay1">
    <div class="modal-block" id="modal1" >
      <div class="modal-block-header">
<!--        <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>-->
      </div>
      <div class="modal-block-body">
        <div class="modal-container">

          <div class="modal-container-block">
            <div class="close-block">
              <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
            </div>
            <div class="modal-container-block-form">
              <div class="block-form-title">
                Заполни форму, чтобы записаться на игру
              </div>
              <div class="block-form-body">

                <div class="form-body-row">
                  <div class="form-body-row-title">Укажите ваше имя <span v-if="er_name !== ''" class="form__error">{{ er_name }}</span></div>
                  <div class="form-body-row-block">
                    <input
                        type="text"
                        placeholder="Александр"
                        v-bind:value="block.firstname"
                        @input="block.firstname = $event.target.value"
                    >
                  </div>

                </div>

                <div class="form-body-row">
                  <div class="form-body-row-title">Укажите ваш номер телефона <span v-if="er_phone !== ''" class="form__error">{{ er_phone }}</span></div>
                  <div class="form-body-row-block">
                    <input
                        type="tel"
                        minlength="12" maxlength="18"
                        placeholder="+7 (995) 988-11-98"
                        v-bind:value="block.phone"
                        @input="block.phone = $event.target.value"
                    >
                  </div>
                </div>

                <div class="form-body-row">
                  <div class="form-body-row-title">Укажите ваш mail <span v-if="er_mail !== ''" class="form__error">{{ er_mail }}</span></div>
                  <div class="form-body-row-block">
                    <input
                        type="email"
                        placeholder="mail@mail.ru"
                        v-bind:value="block.mail"
                        @input="block.mail = $event.target.value"
                    >
                  </div>

                </div>

                <div class="form-body-row">
                  <div class="form-body-row-title">Дата и время <span v-if="er_mail !== ''" class="form__error">{{ er_mail }}</span></div>
                  <div class="form-body-row-block">
                    <input
                        type="datetime-local"
                        placeholder=""
                        v-bind:value="block.time"
                        @input="block.time = $event.target.value"
                    >
                  </div>

                </div>

                <div class="form-body-row">
                  <div class="form-body-row-title">Кол-во игроков <span v-if="er_mail !== ''" class="form__error">{{ er_mail }}</span></div>
                  <div class="form-body-row-block">
                    <input
                        type="number"
                        placeholder=""
                        v-bind:value="block.players_count"
                        @input="block.players_count = $event.target.value"
                    >
                  </div>

                </div>

                <div class="form-body-row-btn">
                  <div class="form-btn" @click="submitForm">Записаться</div>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-container-block" style="background-image: url('/imgs/img3.jpg')">
            <div class="modal-container-block-contacts">
              <div class="modal-container-block-contacts-item">
                <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;"><path fill="white" d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z"></path></svg>
                <div class="contacts-item-text">
                  <div class="contacts-item-title">Адрес</div>
                  <div class="contacts-item-description">ул. Планетарная 59, ТРЦ "Leo Mall" (3 этаж)</div>
                </div>

              </div>
              <div class="modal-container-block-contacts-item">
                <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;"><path fill="white" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"></path></svg>
                <div class="contacts-item-text">
                  <div class="contacts-item-title">Телефон</div>
                  <div class="contacts-item-description">+7 (995) 988-11-98</div>
                </div>

              </div>
              <div class="modal-container-block-contacts-item">
                <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;"><path fill="white" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"></path></svg>
                <div class="contacts-item-text">
                  <div class="contacts-item-title">Mail</div>
                  <div class="contacts-item-description">vrizion@mail.ru</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xxl" style="height: 100vh;">
    <div class="row h-50 home-section1 align-items-end">
      <div class="col-lg-7 col-sm-12 wow fadeInLeft">
        <h1 class="home-title wow" id="home-title">{{ title_text }}</h1>
        <div class="home-text">{{ description_text }}</div>
        <button type="button" class="home-container-btn" @click="openModal">ЗАПИСАТЬСЯ НА ИГРУ</button>
      </div>
    </div>
    <div class="row h-50 home-section2 wow fadeInRight">
      <div class="col-lg-6 home-left"></div>
      <div class="col-lg-6 col-sm-12 home-right">
        <div class="video-block">
          <video width="100%" height="100%" controls style="background: transparent!important">
            <source src="video/video.mp4" type="video/mp4">
          </video>
        </div>

      </div>
    </div>
  </div>
  <div class="arrow-down">
    <img src="scroll-down-icon.svg">
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "homeContainer",
  data(){
    return{
      block_id: 1,
      title_text: "",
      description_text: "",
      er_name: '',
      er_phone: '',
      er_mail: '',
      block: {
        firstname: '',
        phone: '',
        mail: '',
        time: '',
        players_count: 1,

      },
    }
  },
  methods: {
    submitForm(){
      this.er_name = '';
      this.er_phone = '';
      this.er_mail = '';


      if (this.block.firstname === '') {
        this.er_name = 'Требуется указать имя!';
      }
      if (this.block.phone !== '') {
        const reg = new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$');
        if(!reg.test(this.block.phone) && this.block.phone.length < 12){
          this.er_phone = 'Телефон указан неверно!';
        }


      }else{
        this.er_phone = 'Требуется указать телефон!';
      }

      if (this.block.mail !== '') {
        const reg = new RegExp('[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+');
        if(!reg.test(this.block.mail)){
          this.er_mail = 'Почта указана неверно!';
        }

      }

      if(this.er_name === '' && this.er_phone === ''){
        axios
            .post('https://vrizion.ru/server/contentLoader.php?submit_form', {
              block: this.block
            })
            .then(response => {

              console.log(response.data)
              this.block = {}
              this.closeModal()
            })
            .catch(() => {

            });
      }

    },
    openModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay1");
      body.style.overflowY = 'hidden'
      overlay.style.display = 'flex'
    },
    closeModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay1");
      body.style.overflowY = 'auto'
      overlay.style.display = 'none'

      this.er_name = '';
      this.er_phone = '';
      this.er_mail = '';

      this.block.firstname = ''
      this.block.phone = ''
      this.block.mail = ''
    },
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.title_text = response.data[0].title
            this.description_text = response.data[0].description
            console.log(response.data)

          })
          .catch(() => {

          });
    }
  },
  created() {
    this.getText()
  }
}
</script>

<style scoped>
.form-body-row{
  grid-template-rows: 54px 54px;
}
.form-body-row-block{
  height: 54px;
}
.form-body-row-title{
  justify-content: space-between;
}
.form__error {
  color: red;
  text-align: left;
  font-size: 12px;
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>