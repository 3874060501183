<template>
  <div class="container block-title-container wow fadeInLeft">
    <div class="row justify-content-center title-block">
      <div class="col-lg-6 col-xs-12">
        <div class="title-block-center">
          <div class="block-title-description">
            <h1 class="title-text" style="width: max-content;">{{ title_text }}</h1>
          </div>
          <div class="block-title-description">
            <h6 class="title-bottom">{{ description_text }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="map-container" style="position:relative;overflow:hidden;">
    <div class="map-section wow fadeInLeft">
      <div class="map-block">
        <div class="map-block-title">НАШИ КОНТАКТЫ</div>
        <div class="map-block-description">
          <div v-for="item in about" :key="item.id">{{item.title}}<br></div>

        </div>
      </div>

    </div>
    <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=49523461874" width="100%" height="600" frameborder="0"></iframe>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "aboutContainer",
  data(){
    return{
      block_id: 6,
      title_text: "",
      description_text: "",
      about: []
    }
  },
  methods: {
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.title_text = response.data[0].title
            this.description_text = response.data[0].description
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getAbout(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_about')
          .then(response => {
            this.about = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },

  },
  created() {
    this.getText()
    this.getAbout()
    // this.getCards()
  }
}
</script>

<style scoped>

</style>