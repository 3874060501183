<template>

  <PriceModal v-show="modalView" @update="getSubs" @close="closeModal" :item_obj="itemObj"></PriceModal>

  <div class="admin-page-title">Редактирование блока цен</div>
  <div class="admin-page-block-desc">
    <label>Заголовок</label>
    <input type="text"
           v-bind:value="block.page_title"
           @input="block.page_title = $event.target.value"
    >
    <label>Описание</label>
    <textarea name="" id="" cols="20" rows="5"
              v-bind:value="block.page_desc"
              @input="block.page_desc = $event.target.value"></textarea>
    <div class="btn-save" @click="saveContent">Сохранить</div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка карточек цен
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isPriceBlock = !isPriceBlock">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isPriceBlock">
      <div class="cat-row">
        <input type="text" name="" placeholder="Введите название" v-bind:value="new_price" @input="new_price = $event.target.value">
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" id="cat-icon-success" @click="addPrice"><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>
      </div>
      <div class="cat-row" v-for="price in prices" :key="price">
        <input type="text"
               v-bind:value="price.title"
               @input="price.title = $event.target.value">
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" id="cat-icon-success" @click="UpdatePrice(price.id, price.title)"><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="DeletePrice(price.id)"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
      </div>
    </div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка карточек абониментов
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isSubBlock = !isSubBlock">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isSubBlock">
      <div class="admin-card-game second" v-for="sub in subs" :key="sub" @click="openModal(sub)" :style="{ backgroundImage: 'url(https://vrizion.ru/images/sales/' + sub.img + ')' }">{{sub.title}}</div>
    </div>
  </div>

</template>

<script>

import axios from "axios";
import PriceModal from "@/components/UI/PriceModal";
export default {
  name: "AdminPrice",
  components: {
    PriceModal
  },
  data(){
    return{
      block_id: 4,
      block: {
        page_title: "",
        page_desc: ""
      },
      isPriceBlock: false,
      isSubBlock: false,
      subs: [],
      prices: [],
      new_price: "",
      modalView: false,
      itemObj: []

    }
  },
  methods:{
    openModal(obj){
      this.modalView = true
      this.itemObj = obj

    },
    closeModal(){
      this.modalView = false
    },
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.block.page_title = response.data[0].title
            this.block.page_desc = response.data[0].description
            // console.log(response.data)

          })
          .catch(() => {

          });
    },
    saveContent(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_page_texts', {
            block: this.block, page_id: this.block_id
          })
          .then(response => {
            this.getText()
            console.log(response)

          })
          .catch(() => {

          });
    },
    addPrice(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?add_price', {
            price_name: this.new_price
          })
          .then(response => {
            this.new_price =  ""
            this.getPrice()
            console.log(response)

          })
          .catch(() => {

          });
    },
    getSubs(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_subs_admin')
          .then(response => {
            this.subs = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getPrice(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_prices_admin')
          .then(response => {
            this.prices = []

            this.prices = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    UpdatePrice(id, title){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?update_price_admin', {
            price_id: id, price_title: title
          })
          .then(response => {
            this.getPrice()
            console.log(response)

          })
          .catch(() => {

          });
    },
    DeletePrice(id){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?delete_price_admin', {
            price_id: id
          })
          .then(response => {
            this.getPrice()
            console.log(response)

          })
          .catch(() => {

          });
    },
    UpdateSub(id, title){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?update_sub_admin', {
            category_id: id, category_title: title
          })
          .then(response => {
            this.getCategories()
            console.log(response)

          })
          .catch(() => {

          });
    },
    DeleteSub(id){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?delete_sub_admin', {
            sub_id: id
          })
          .then(response => {
            this.getSubs()
            console.log(response)

          })
          .catch(() => {

          });
    }
  },
  mounted() {
    this.getText()
    this.getSubs()
    this.getPrice()

  }
}
</script>

<style scoped>

</style>