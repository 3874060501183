<template>
  <div class="admin-page-title">Редактирование блока о нас</div>
  <div class="admin-page-block-desc">
    <label>Заголовок</label>
    <input type="text"
           v-bind:value="block.page_title"
           @input="block.page_title = $event.target.value"
    >
    <label>Описание</label>
    <textarea name="" id="" cols="20" rows="5"
              v-bind:value="block.page_desc"
              @input="block.page_desc = $event.target.value"></textarea>
    <div class="btn-save" @click="saveContent">Сохранить</div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка контактной информации
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isContactBlock = !isContactBlock">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isContactBlock" style="display: block;">
      <div class="admin-contact-block">
        <div class="admin-contact-block-row" v-for="contact in contact_info" :key="contact.id">
          <input type="text"
             v-bind:value="contact.title"
             @input="new_contact_info[contact.id] = $event.target.value"
        ></div>
      </div>
      <div class="btn-save" @click="saveContacts">Сохранить</div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
export default {
  name: "AdminAbout",
  data(){
    return{
      block_id: 6,
      block: {
        page_title: "",
        page_desc: ""
      },
      isContactBlock: false,
      contact_info: [],
      new_contact_info: {}

    }
  },
  methods:{
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.block.page_title = response.data[0].title
            this.block.page_desc = response.data[0].description
            // console.log(response.data)

          })
          .catch(() => {

          });
    },
    saveContent(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_page_texts', {
            block: this.block, page_id: this.block_id
          })
          .then(response => {
            this.getText()
            console.log(response)

          })
          .catch(() => {

          });
    },

    saveContacts(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_contacts_admin', {
            contact_info: this.new_contact_info
          })
          .then(response => {
            this.getContacts()
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getContacts(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_contacts_admin')
          .then(response => {
            this.contact_info = []
            this.contact_info = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },
  },
  mounted() {
    this.getText()
    this.getContacts()

  }
}
</script>

<style scoped>

</style>