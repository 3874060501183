import {createRouter, createWebHistory} from "vue-router";
import HomePage from "@/components/HomePage";
// import AdminPage from "@/components/AdminPage";
import AdminGames from "@/components/UI/AdminGames";
import AdminHome from "@/components/UI/AdminHome";
import AdminSales from "@/components/UI/AdminSales";
import AdminPrice from "@/components/UI/AdminPrice";
import AdminGallery from "@/components/UI/AdminGallery";
import AdminAbout from "@/components/UI/AdminAbout";

const routes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/u2046159',
        component: AdminHome
    },
    {
        path: '/u2046159/home',
        component: AdminHome
    },
    {
        path: '/u2046159/sales',
        component: AdminSales
    },
    {
        path: '/u2046159/games',
        component: AdminGames
    },
    {
        path: '/u2046159/price',
        component: AdminPrice
    },
    {
        path: '/u2046159/gallery',
        component: AdminGallery
    },
    {
        path: '/u2046159/about',
        component: AdminAbout
    }



]


const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})


// router.beforeEach(async (to) => {
//
//     if (!user && to.path !== '/login') {
//         return {path: '/login'}
//     }
// })

export default router