<template>
  <AdminPage v-if="url.includes('/u2046159')"></AdminPage>
  <HomePage v-else></HomePage>


</template>

<script>


import AdminPage from "@/components/AdminPage";
import HomePage from "@/components/HomePage";

export default {
  name: 'App',
  components: {AdminPage, HomePage},
  data(){
    return{
      url: window.location.pathname
    }
  },
  methods:{

  },




}
</script>

<style>

</style>
