<template>
  <div class="container block-title-container wow fadeInRight">
    <div class="row justify-content-center title-block">
      <div class="col-lg-6 col-xs-12">
        <div class="title-block-center">
          <div class="block-title-description">
            <h1 class="title-text">{{ title_text }}</h1>
          </div>
          <div class="block-title-description">
            <h6 class="title-bottom">{{ description_text}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container gal-container">
    <div class="row">

      <div class="col-lg-4 col-sm-6 co-xs-12 gal-item wow fadeInLeft" v-for="item in images" :key="item.id">
        <div class="box">
          <img :src='"/images/gallery/"+item.url'>
        </div>
      </div>

    </div>
  </div>
<!--  <div class="games-counter">-->
<!--    <div class="games-counter-block" style="margin-bottom: 2rem;">-->
<!--      <div class="all-games-btn">СМОТРЕТЬ ВСЕ ФОТО</div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import axios from "axios";
export default {
  name: "galleryContainer",
  data(){
    return{
      block_id: 5,
      title_text: "",
      description_text: "",
      images: []
    }
  },
  methods: {
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.title_text = response.data[0].title
            this.description_text = response.data[0].description
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getImages(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_photos')
          .then(response => {
            this.images = response.data

          })
          .catch(() => {

          });
    }
  },
  created() {
    this.getText()
    this.getImages()
  }
}
</script>

<style scoped>

</style>