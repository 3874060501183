<template>
  <div class="container block-title-container">
    <div class="row justify-content-center title-block wow fadeInLeft">
      <div class="col-lg-6 col-xs-12">
        <div class="title-block-center">
          <div class="block-title-description">
            <h1 class="title-text">{{ title_text }}</h1>
          </div>
          <div class="block-title-description">
            <h6 class="title-bottom">{{ description_text }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container gal-container">
    <div class="row">
      <div class="col-sm-12 co-xs-12 gal-item wow fadeInLeft" :class='[ item.id % 2 === 1 ? "col-lg-8" : "col-lg-4" ]' v-for="item in sales" :key="item.id">
        <div class="box" :style="{ backgroundImage: 'url(https://vrizion.ru/images/promo/' + item.image + ')' }" style="background-size: cover;">
          <div data-v-3e7334d0="" class="game-card-text" style="opacity: 1;height: 120px">
            <div data-v-3e7334d0="" class="game-card-text-title">{{ item.title }}</div>
            <div data-v-3e7334d0="" class="game-card-text-description" v-html="item.description"></div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "salesContainer",
  data(){
    return{
      block_id: 2,
      title_text: "",
      description_text: "",
      sales: []
    }
  },
  methods: {
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.title_text = response.data[0].title
            this.description_text = response.data[0].description
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getSales(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_sales')
          .then(response => {
            this.sales = response.data

          })
          .catch(() => {

          });
    }
  },
  created() {
    this.getText()
    this.getSales()
  }
}
</script>

<style scoped>
.box{
  display: flex;
  align-items: end;
}
.game-card-text{
  width: 100%;
}
.game-card-text-description{
  text-transform: initial;
}
</style>