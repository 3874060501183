<template>
  <div class="container block-title-container wow fadeInLeft">
    <div class="row justify-content-center title-block">
      <div class="col-lg-6 col-xs-12">
        <div class="title-block-center">
          <div class="block-title-description">
            <h1 class="title-text">{{ title_text }}</h1>
          </div>
          <div class="block-title-description">
            <h6 class="title-bottom">{{ description_text}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="articles-price" style="padding-bottom: 6rem;">
    <div class="game-card-min wow fadeInLeft" v-for="card in cards['min']" :key="card.id">
      <div class="game-card-text-min">
        <div class="game-card-text-title">{{ card.title }}</div>
      </div>
    </div>
  </section>
  <div class="section-titile">
    <h1 class="wow fadeInRight">АБОНЕМЕНТЫ</h1>
  </div>
  <section class="articles-price" style="padding-bottom: 6rem;">
    <div class="price-card wow fadeInRight" v-for="sub in subs" :key="sub.id" :style="{ backgroundImage: 'url(https://vrizion.ru/images/sales/' + sub.img + ')' }">
      <div class="price-card-text">
        <div class="price-card-text-title">{{ sub.title }}</div>
        <div class="price-card-text-description">{{ sub.desc }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "priceContainer",
  data(){
    return{
      block_id: 4,
      title_text: "",
      description_text: "",
      cards: [],
      subs: []
    }
  },
  methods: {
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.title_text = response.data[0].title
            this.description_text = response.data[0].description
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getCards(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_price_cards')
          .then(response => {
            this.cards = response.data

          })
          .catch(() => {

          });
    },
    getSubs(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_subs_admin')
          .then(response => {
            this.subs = response.data

          })
          .catch(() => {

          });
    }
  },
  created() {
    this.getText()
    this.getCards()
    this.getSubs()
  }
}
</script>

<style scoped>
  .section-titile{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skewX(-8deg);
  }
  .section-titile h1{
    padding: 1rem;
    background-color: #090909;
    color: white;
    font-weight: bold;

  }
</style>