import AdminGames from "@/components/UI/AdminGames";
import GameModal from "@/components/UI/GameModal";
import PriceModal from "@/components/UI/PriceModal";
import SaleModal from "@/components/UI/SaleModal";
import ('/public/style.css')
export default [
    AdminGames,
    GameModal,
    PriceModal,
    SaleModal,
]