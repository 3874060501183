<template>
  <div class="custom-header">
    <div class="nav-logo">VRIZION</div>

    <ul class="nav-links">
      <li><a href="#element1" class="nav-link px-3 link-light">Главная</a></li>
      <li><a href="#element2" class="nav-link px-3 link-light">Скидки и акции</a></li>
      <li><a href="#element3" class="nav-link px-3 link-light">Наши игры</a></li>
      <li><a href="#element4" class="nav-link px-3 link-light">Цены</a></li>
      <li><a href="#element5" class="nav-link px-3 link-light">Галерея</a></li>
      <li><a href="#element6" class="nav-link px-3 link-light">О нас</a></li>
    </ul>
    <div class="burger-menu" @click="openMenu()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>menu</title><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></svg>
    </div>
    <div class="nav-btns">
      <button type="button" class="nav-btn contacts-btn" @click="isContacts = !isContacts">Наши контакты
        <svg v-if="!isContacts" viewBox="0 0 24 24" style="width:1.5rem;height:1.5rem" role="presentation"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" style="fill:currentColor"></path></svg>
        <svg v-else viewBox="0 0 24 24" style="width:1.5rem;height:1.5rem;rotate: 180deg;" role="presentation"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" style="fill:currentColor"></path></svg>

      </button>
      <transition name="fade">
        <div v-if="isContacts" class="contacts-block">
          <div class="contacts-block-text">
            Адрес: ул. Планерная 59<br>

            ТРЦ "Leo Mall" (3 этаж)<br>

            Метро: Комендантский проспект<br>

            Время работы: 10:00 – 22:00<br>

            E-mail: vrizion@mail.ru<br>

            Телефон: +7 (995) 988-11-98<br>

            <a href="https://vk.com/vrizion" target=»_blank»>
              <img srcset="https://img.icons8.com/?size=512&amp;id=oa4chNZ4S5fa&amp;format=png 2x, https://img.icons8.com/?size=512&amp;id=oa4chNZ4S5fa&amp;format=png 1x" src="https://img.icons8.com/?size=512&amp;id=oa4chNZ4S5fa&amp;format=png 2x" alt="VK icon" width="48" height="48" style="width: 48px; height: 48px;cursor: pointer;">
            </a>

          </div>
        </div>
      </transition>
      <button type="button" class="nav-btn invite-btn" @click="openModal" >Записаться на игру</button>
    </div>

  </div>
  <div class="mobile-menu">
    <div class="mobile-menu-block">
      <ul class="nav-links-m">
        <li><a href="#element1" class="nav-link-m link-light">Главная</a></li>
        <li><a href="#element2" class="nav-link-m link-light">Скидки и акции</a></li>
        <li><a href="#element3" class="nav-link-m link-light">Наши игры</a></li>
        <li><a href="#element4" class="nav-link-m link-light">Цены</a></li>
        <li><a href="#element5" class="nav-link-m link-light">Галерея</a></li>
        <li><a href="#element6" class="nav-link-m link-light">О нас</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBlock",
  data(){
    return{
      isContacts: false,
      isMenu: false
    }
  },
  methods: {
    openMenu(){
      if(!this.isMenu){
        const body = document.querySelector("body");
        const mobile_menu = document.querySelector(".mobile-menu");
        body.style.overflowY = 'hidden'
        mobile_menu.style.display = 'flex'
        this.isMenu = true
      }else{
        const body = document.querySelector("body");
        const mobile_menu = document.querySelector(".mobile-menu");
        body.style.overflowY = 'auto'
        mobile_menu.style.display = 'none'
        this.isMenu = false
      }

    },
    openModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay1");
      body.style.overflowY = 'hidden'
      overlay.style.display = 'flex'
    },
    closeModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay1");
      body.style.overflowY = 'auto'
      overlay.style.display = 'none'
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>