<template>
  <div class="admin-header">
    <div class="admin-header-logo">VRIZION | Панель администратора</div>
    <div class="admin-header-btn">
      <svg viewBox="0 0 24 24" style="height: 32px; width: 32px;">
        <path fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z"></path>
      </svg>
    </div>
  </div>
  <div class="admin-body">
    <div class="admin-nav">
      <div class="admin-nav-list">
        <router-link to="/u2046159/home" routerLinkActive="active">
          <div class="admin-nav-list-link">Главная</div>
        </router-link>

        <router-link to="/u2046159/sales">
          <div class="admin-nav-list-link">Скидки и акции</div>
        </router-link>

        <router-link to="/u2046159/games">
          <div class="admin-nav-list-link">Игры</div>
        </router-link>

        <router-link to="/u2046159/price">
          <div class="admin-nav-list-link">Цены</div>
        </router-link>

        <router-link to="/u2046159/gallery">
          <div class="admin-nav-list-link">Галерея</div>
        </router-link>

        <router-link to="/u2046159/about">
          <div class="admin-nav-list-link">О нас</div>
        </router-link>

      </div>
    </div>
    <div class="admin-content">
      <router-view>

      </router-view>

    </div>
  </div>
</template>

<script>

export default {
  name: "AdminPage",
  data(){
    return{
      isCarousel: false,
      isAllGames: false
    }
  },

}
</script>

<style>

</style>