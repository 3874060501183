<template>
  <div class="admin-page-title">Редактирование блока галереи</div>
  <div class="admin-page-block-desc">
    <label>Заголовок</label>
    <input type="text"
           v-bind:value="block.page_title"
           @input="block.page_title = $event.target.value"
    >
    <label>Описание</label>
    <textarea name="" id="" cols="20" rows="5"
              v-bind:value="block.page_desc"
              @input="block.page_desc = $event.target.value"></textarea>
    <div class="btn-save" @click="saveContent">Сохранить</div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка изображений
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isGallery = !isGallery">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isGallery">
      <div class="admin-card-game" v-for="cart in gallery_cards" :key="cart" style="display: block;height: max-content;    background-origin: border-box;">
        <div :style="{ backgroundImage: 'url(https://vrizion.ru/images/gallery/' + cart.url + ')' }" style="background-blend-mode: unset!important;width:100%;height: 340px;background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;"></div>
        <file-pond
            :name="cart.id"
            allow-multiple="false"
            max-files="1"
            accepted-file-types="image/jpeg, image/png"
            v-bind:files="cart.file"
            :label-idle="label"

            server="https://vrizion.ru/server/file_loader.php"
            v-on:init="handleFilePondInit"
            @processfile="onProcessFile"
            @click="card_id = cart.id"
        />
<!--        <div class="btn-save" @click="saveContent">Сохранить</div>-->
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

import vueFilePond from "vue-filepond";

import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond(

);
export default {
  name: "AdminGallery",
  components: {
    FilePond,
  },
  data(){
    return{
      images: null,
      card_id: null,
      label: "Выберите изображение",
      block_id: 5,
      block: {
        page_title: "",
        page_desc: ""
      },
      isGallery: false,
      gallery_cards: []

    }
  },
  methods:{
    handleFilePondInit: function () {
    },
    onProcessFile(error, file) {
      console.log('file processed', { error, file })

      this.images = file.file

      axios
          .post('https://vrizion.ru/server/file_loader.php', {file: this.images, card_id: this.card_id}, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {

            console.log(response.data)
            this.images = null
            this.card_id = null
            this.getGallery()
          })
          .catch(() => {

          });

    },

    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.block.page_title = response.data[0].title
            this.block.page_desc = response.data[0].description
            // console.log(response.data)

          })
          .catch(() => {

          });
    },
    saveContent(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_page_texts', {
            block: this.block, page_id: this.block_id
          })
          .then(response => {
            this.getText()
            console.log(response)

          })
          .catch(() => {

          });
    },

    getGallery(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_gallery_admin')
          .then(response => {
            this.gallery_cards = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },
  },
  mounted() {
    this.getText()
    this.getGallery()

  }
}
</script>

<style scoped>

</style>