<template>

    <GameModal v-show="modalView" @update="getGames" @close="closeModal" :item_obj="itemObj" :blank="blank" :games_categories="categories_games"></GameModal>

  <div class="admin-page-title">Редактирование блока игр</div>
  <div class="admin-page-block-desc">
    <label>Заголовок</label>
    <input type="text"
       v-bind:value="block.page_title"
       @input="block.page_title = $event.target.value"
    >
    <label>Описание</label>
    <textarea name="" id="" cols="20" rows="5"
      v-bind:value="block.page_desc"
      @input="block.page_desc = $event.target.value"></textarea>
    <div class="btn-save" @click="saveContent">Сохранить</div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка карусели игр
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isCarousel = !isCarousel">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isCarousel">
      <div class="admin-card-game" v-for="car in carousel_games" :key="car" @click="openModal(car)" :style="{ backgroundImage: 'url(https://vrizion.ru/images/games/carousel/' + car.image + ')' }">{{car.title}}</div>
    </div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка списка игр
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isAllGames = !isAllGames">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isAllGames">
      <div class="admin-card-game-blank" @click="openBlankModal">
        <svg viewBox="0 0 24 24" style="height: 80px; width: 80px;">
          <path fill="currentColor" d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z"></path>
        </svg>
      </div>
      <div class="admin-card-game second" v-for="game in all_games" :key="game" @click="openModal(game)" :style="{ backgroundImage: 'url(https://vrizion.ru/images/games/list/' + game.image + ')' }">
        <div class="second-text">{{game.category_name}}</div></div>
    </div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка категорий игр
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isCategoriesGames = !isCategoriesGames">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isCategoriesGames">
      <div class="cat-row">
        <input type="text" name="" placeholder="Введите название" v-bind:value="new_category" @input="new_category = $event.target.value">
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" id="cat-icon-success" @click="addCategory"><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>
      </div>
      <div class="cat-row" v-for="cat in categories_games" :key="cat">
        <input type="text"
             v-bind:value="cat.title"
             @input="cat.title = $event.target.value">
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" id="cat-icon-success" @click="UpdateCategory(cat.id, cat.title)"><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="DeleteCategory(cat.id)"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GameModal from "@/components/UI/GameModal";

export default {
  name: "AdminGames",
  components: [GameModal],
  data(){
    return{
      blank: false,
      block_id: 3,
      block: {
        page_title: "",
        page_desc: ""
      },
      new_category: "",
      carousel_games: [],
      all_games: [],
      categories_games: [],
      isCarousel: false,
      isAllGames: false,
      isCategoriesGames: false,
      modalView: false,
      itemObj: []
    }
  },
  methods: {
    openModal(obj){
      this.modalView = true
      this.itemObj = obj

    },
    openBlankModal(){
      this.modalView = true
      this.blank = true
    },
    closeModal(){
      this.modalView = false
      this.blank = false
    },
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.block.page_title = response.data[0].title
            this.block.page_desc = response.data[0].description
            // console.log(response.data)

          })
          .catch(() => {

          });
    },

    getCarouselGames(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_carousel_admin')
          .then(response => {
            this.carousel_games = response.data

            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getGames(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_all_games_admin')
          .then(response => {
            this.all_games = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getCategories(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_games_categories_admin')
          .then(response => {
            this.categories_games = []
            this.categories_games = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },

    saveContent(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_page_texts', {
            block: this.block, page_id: this.block_id
          })
          .then(response => {
            this.getText()
            console.log(response)

          })
          .catch(() => {

          });
    },

    addCategory(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?add_category', {
            category_name: this.new_category
          })
          .then(response => {
            this.new_category =  ""
            this.getCategories()
            console.log(response)

          })
          .catch(() => {

          });
    },
    UpdateCategory(id, title){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?update_category', {
            category_id: id, category_title: title
          })
          .then(response => {
            this.getCategories()
            console.log(response)

          })
          .catch(() => {

          });
    },
    DeleteCategory(id){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?delete_category', {
            category_id: id
          })
          .then(response => {
            this.getCategories()
            console.log(response)

          })
          .catch(() => {

          });
    }
  },
  mounted() {
    this.getText()
    this.getCarouselGames()
    this.getGames()
    this.getCategories()
  }
}
</script>

<style scoped>
/*.btn-save{*/
/*  margin-top: 0.5rem;*/
/*  padding: 0.5rem 1rem;*/
/*  background-color: #00c56c;*/
/*  width: -moz-max-content;*/
/*  width: max-content;*/
/*  color: black;*/
/*  border-radius: 4px;*/
/*  cursor: pointer;*/
/*}*/
/*.card-game{*/
/*  background-position: center;*/
/*  background-size: cover;*/
/*  background-repeat: no-repeat;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-weight: bold;*/
/*  font-size: 18px;*/
/*  background-blend-mode: overlay;*/
/*}*/
/*.overlay{*/
/*  display: flex;*/
/*}*/
/*.second{*/
/*  display: flex;*/
/*  align-items: start;*/
/*  justify-content: start;*/
/*  padding: 1rem;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  height: 370px;*/
/*}*/
/*.cat-row{*/
/*  border-radius: 4px;*/
/*  padding: 1rem;*/
/*  background-color: #25282b;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/
/*.cat-row input{*/
/*  width: 100%;*/
/*  background-color: transparent;*/
/*  border: 0;*/
/*  color: white;*/
/*}*/

/*.cat-row svg, #cat-icon-success{*/
/*  cursor: pointer;*/
/*  transition: 0.5s;*/
/*  margin-left: 0.25rem;*/
/*}*/
/*#cat-icon-success:hover{*/
/*  color: lightgreen;*/
/*}*/
/*.cat-row svg:hover{*/
/*  color: tomato;*/
/*}*/
</style>