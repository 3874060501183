<template>
  <div class="video-overlay" id="overlay3" style="z-index: 1000;">
    <div class="video-modal">
      <svg @click="closeVideoModal" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" style="
      cursor: pointer;
    float: right;
    margin: 0.5rem;
    fill: white;
" viewBox="0 0 20 20"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
      <div class="video-container" style="z-index: 1000;">

        <video controls :src="'https://vrizion.ru/images/games/video/' + item_video" v-if="isVideo" style="z-index: 1000;"></video>
<!--        <iframe id="overlay3" width="1271" height="715" src="https://www.youtube.com/embed/DPJup_T4JSU?controls=1&modestbranding=1&showinfo=0&rel=0&autoplay=1" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; loop;" allowfullscreen></iframe>-->
      </div>

    </div>
  </div>

  <div class="overlay" id="overlay2">
    <div class="modal-block" id="modal2">
      <div class="modal-block-header">
        <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
      </div>
      <div class="modal-block-body" v-for="cat in games_list.category" :key="cat.id">
        <h1>{{ cat.title }}</h1>
        <div class="modal-body-section">
          <div class="modal-body-card" v-for="item in cat.list" :key="item.id" v-bind:style="{ backgroundImage: 'url(https://vrizion.ru/images/games/list/' + item.img + ')' }">
            <div class="modal-card-text" style="display: flex; align-items: center; justify-content: center;">
              <svg viewBox="0 0 24 24" style="height: 78px; width: 78px;" @click="openVideo(item.video)">
                <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M10,16.5L16,12L10,7.5V16.5Z"></path>
              </svg>
<!--              <svg viewBox="0 0 24 24" style="height: 78px; width: 78px;">-->
<!--                <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M10,16.5L16,12L10,7.5V16.5Z"></path>-->
<!--              </svg>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container block-title-container wow fadeInRight">
    <div class="row justify-content-center title-block">
      <div class="col-lg-5 col-xs-12">
        <div class="title-block-center">
          <div class="block-title-description">
            <h1 class="title-text">{{ title_text }}</h1>
          </div>
          <div class="block-title-description">
            <h6 class="title-bottom">{{ description_text }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="slider-container" item-display-d="4" item-display-t="3" item-display-m="1">
    <button type="button" class="btn" id="left-btn">
      <svg viewBox="0 0 24 24" style="height: 50px; width: 50px;"><path fill="white" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
    </button>
    <button type="button" class="btn" id="right-btn">
      <svg viewBox="0 0 24 24" style="height: 50px; width: 50px;"><path fill="white" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg>
    </button>
    <div class="slider-width">
      <div class="item" v-for="game in games" :key="game.id" v-bind:style="{ backgroundImage: 'url(/images/games/carousel/' + game.image + ')' }">
        <div class="game-card-text">
          <div class="game-card-text-title">{{ game.title }}</div>
          <div class="game-card-text-description">{{game.description}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="carousel-btns">

  </div>
  <div class="games-counter">
    <div class="games-counter-block" style="margin-bottom: 2rem;">
      <div class="all-games-btn" @click="openModal">ПОЛНЫЙ СПИСОК ИГР</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "gamesContainer",
  data(){
    return{
      block_id: 3,
      title_text: "",
      description_text: "",
      games: [],
      games_list: [],
      isVideo: false,
      item_video: ""
    }
  },
  methods: {
    openVideo(video){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay3");
      body.style.overflowY = 'hidden'
      overlay.style.display = 'flex'
      this.item_video = video
      this.isVideo = true
    },
    openModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay2");
      body.style.overflowY = 'hidden'
      overlay.style.display = 'flex'
    },
    closeModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay2");
      body.style.overflowY = 'auto'
      overlay.style.display = 'none'
    },
    closeVideoModal(){
      const body = document.querySelector("body");
      const overlay = document.getElementById("overlay3");
      body.style.overflowY = 'auto'
      overlay.style.display = 'none'
      this.isVideo = false
    },
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.title_text = response.data[0].title
            this.description_text = response.data[0].description
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    getAllGames(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_all_games')
          .then(response => {
            this.games_list = response.data
            console.log(response.data)
          })
          .catch(() => {

          });
    },
    getGames(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_games_carousel')
          .then(response => {
            this.games = response.data

          })
          .catch(() => {

          });
    }
  },
  mounted() {
    this.getText()
    this.getGames()
    this.getAllGames()
  }
}
</script>

<style scoped>
.slider-container{
  position: relative;
  overflow-x: hidden;
  height: 460px;
  margin-top: 2rem;
}
.slider-width{
  position: absolute;
  display: flex;
  align-items: start;
  justify-content: start;
  left: 0;
  top: 0;
  transition: 0.4s ease-in-out;
}
.item{
  width: 300px;
  display: flex;
  padding: 0;
  text-decoration: none;
  transform: skewX(-6deg);
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  margin: 0 10px;
  color: white;
  background: #6225E6;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  overflow: hidden;
  font-weight: bolder;
  height: 450px;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: end;
  cursor: pointer;
}
.item:hover{
  box-shadow: 10px 10px 0 #FBC638;
}
.item:hover .game-card-text{
  height: 250px;
  opacity: 100;
}
.carousel-btns{
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  margin-top: 2rem;
}
.btn{
  padding: 0;
  width: 60px;
  height: 60px;
  border: 5px solid white;
  transition: 1s;
  transform: skewX(-6deg) translateY(-50%);
  border-radius: 0;
  position: absolute;
  background-color: black;
  box-shadow: 6px 6px 0 black;
  cursor: pointer;
  top: 50%;
  z-index: 1;
}
#left-btn{
  left: 50px;
  top: 56px;
}
#right-btn{
  right: 50px;
  top: 56px;
}
</style>