<template>
  <div class="block" id="element1">

    <!--НАВИГАЦИЯ------------------------------------------------------------------------------------>

    <nav-block></nav-block>

    <!--------------------------------------------------------------------------------------------------->

    <!--ГЛАВНЫЙ ЭКРАН------------------------------------------------------------------------------------>

    <home-container></home-container>

    <!--------------------------------------------------------------------------------------------------->
  </div>
  <div class="block" id="element2">

    <!--СКИДКИ АКЦИИ------------------------------------------------------------------------------------>

    <sales-container></sales-container>

    <!--------------------------------------------------------------------------------------------------->

  </div>
  <div class="block" id="element3">
    <!--БЛОК С ИГРАМИ------------------------------------------------------------------------------------>

    <games-container></games-container>

    <!--------------------------------------------------------------------------------------------------->
  </div>
  <div class="block" id="element4">
    <!--НАШИ ЦЕНЫ------------------------------------------------------------------------------------>

    <price-container></price-container>

    <!--------------------------------------------------------------------------------------------------->
  </div>
  <div class="block" id="element5">
    <!--ГАЛЕРЕЯ------------------------------------------------------------------------------------>

    <gallery-container></gallery-container>

    <!--------------------------------------------------------------------------------------------------->

  </div>

  <div class="block" id="element6">
    <!--О НАС------------------------------------------------------------------------------------>

    <about-container></about-container>

    <!--------------------------------------------------------------------------------------------------->

    <div class="footer">© 2023. VRIZION. Все права защищены. Копирование материалов сайта запрещено</div>

  </div>
</template>

<script>
import HomeContainer from "@/components/homeContainer";
import salesContainer from "@/components/salesContainer";
import GamesContainer from "@/components/gamesContainer";
import priceContainer from "@/components/priceContainer";
import galleryContainer from "@/components/galleryContainer";
import aboutContainer from "@/components/aboutContainer";
import NavBlock from "@/components/navBlock";

export default {
  name: "HomePage",
  components: {NavBlock, GamesContainer, HomeContainer, salesContainer, priceContainer, galleryContainer, aboutContainer},
}
</script>

<style scoped>

</style>