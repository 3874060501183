<template>
  <div class="overlay" @click="modalClose">
    <div class="modal_block" @click.stop>
      <div class="modal-top">
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="modalClose">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
        </svg>
      </div>
      <div class="modal-body">
        <div class="modal-left">
          <div class="modal-left-img" :style="{ backgroundImage: 'url(https://vrizion.ru/images/sales/' + item_obj.img + ')' }"></div>
        </div>

        <div class="modal-right" >
          <div class="modal-right-block">
            <div class="modal-right-block-row">
              <span class="bl-title">Название:</span>
              <input type="text" name=""
                     :value="item_obj.title"
                     @input="item.title = $event.target.value">
            </div>
            <div class="modal-right-block-row">
              <span class="bl-title">Описание:</span><span class="bl-desc"></span>
              <textarea name="" id="" cols="30" rows="10"
                        :value="item_obj.desc"
                        @input="item.desc = $event.target.value"></textarea>
            </div>
            <div class="modal-right-block-row">
              <span class="bl-title">Изображение:</span><span class="bl-desc"></span>
              <file-pond
                  name="test"
                  allow-multiple="false"
                  max-files="1"
                  accepted-file-types="image/jpeg, image/png"
                  v-bind:files="file"
                  :label-idle="label"

                  server="https://vrizion.ru/server/file_loader.php"
                  v-on:init="handleFilePondInit"
                  @processfile="onProcessFile"
                  @click="card_id = item_obj.id"
              />
            </div>
          </div>
          <div class="btns-block">
            <div class="btn-save" @click="saveContent(item_obj.id)">Сохранить</div>
          </div>

        </div>


        </div>
      </div>

    </div>

</template>

<script>
import axios from "axios";

import vueFilePond from "vue-filepond";

import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond(

);
export default {
  name: "PriceModal",
  components: {
    FilePond,
  },
  data(){
    return{
      images: null,
      card_id: null,
      item: {
        title: "",
        desc: ""
      },
      file: "",
      label: "Выберите изображение",
    }
  },
  props: {
    item_obj: {
      type: Object,
      required: true
    },

  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      this.new_img = URL.createObjectURL(file);
      this.block.img = file.name
      this.image_file = event.target.files[0];
    },
    saveContent(id){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_sub', {
            card_id: id, item: this.item, obj: this.item_obj
          })
          .then(response => {
            this.$emit('update', true)
            console.log(response.data)

          })
          .catch(() => {

          });
    },
    handleFilePondInit: function () {
      // console.log(this.myFiles)

    },

    onProcessFile(error, file) {
      console.log('file processed', { error, file })

      this.images = file.file

      axios
          .post('https://vrizion.ru/server/sub_file_loader.php', {file: this.images, obj: this.item_obj}, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {

            console.log(response.data)
            this.images = null
            this.card_id = null
            this.props.item_obj.image = file.file.name
            this.$emit('update', true)


          })
          .catch(() => {

          });

    },
    getCategories(){
      if(this.item_obj.id){
        axios
            .post('https://vrizion.ru/server/contentLoader.php?get_categories')
            .then(response => {
              this.categories = response.data

              console.log(response.data)

            })
            .catch(() => {

            });
      }
    },
    modalClose(){
      this.$emit('close', true)
      this.item_cat = ""
    }
  },
  mounted() {
    this.getCategories()
  }
}
</script>

<style scoped>
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0,0,0,.4);
}
.modal_block{
  padding-bottom: 3rem;
  width: 1150px;
  background-color: #312f3d;
  margin: auto;
  border-radius: 4px;
}
.modal-top{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 60px;
}
.modal-top svg{
  margin-right: 1rem;
  color: #bbb9c7;
  cursor: pointer;
  transition: 0.5s;
}
.modal-top svg:hover{
  color: white;
}
.modal-body{
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.modal-left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-right{
  margin: 0 3rem;
}
.modal-right-text{
  display: flex;
  align-items: center;
}
.modal-right-text svg{
  margin-left: 0.5rem;
}
.modal-right-block{
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  height: max-content;
  background-color: #282633;
}
.modal-right-block-row{
  display: block;
  justify-content: space-between;
  padding: 0 0 0.5rem 0;
}
.modal-right-block-row-desc{
  color: #696777;
  font-size: 12px;
}
.row-line{
  width: 100%;
  height: 1px;
  background-color: #3e3c48;
  margin: 1rem 0;
}
.bl-title{
  color: #bbb9c7;
  font-size: 13px;
  display: block;
  margin-bottom: 0.5rem;
}
.bl-desc{
  color: white;
  font-weight: 600;
  font-size: 14px;
}
.modal-input{
  padding: 0.2rem;
  outline: none;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #bbb9c7;
  color: white;
}
.bl-text{
  margin-right: 0.5rem;
  font-size: 13px;
  padding: 0.3rem 0.4rem;
  border-radius: 4px;
  color: #7bc377;
  background-color: rgb(123 195 119 / 10%);
}
.modal-left-img{
  width: 80%;
  height: 80%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.5s;
  cursor: zoom-in;
}

.modal-right-text{
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.modal-right-block-row input, textarea{
  display: block;
  width: 100%;
  background-color: #32303f5e;
  border: 0;
  outline: none;
  padding: 0.5rem;
  border-radius: 4px;
  color: white;
}

</style>