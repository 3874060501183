<template>
  <div class="overlay" @click="modalClose">
    <div class="modal_block" @click.stop>
      <div class="modal-top">
        <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="modalClose">
          <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
        </svg>
      </div>
      <div class="modal-body">
        <div>

          <div>
            <span class="bl-title">Наименование:</span>
            <input type="text"
               :value="item_obj.title"
               @input="block.title = $event.target.value">
          </div>

          <div class="modal-content-block">
            <div>
              <span class="bl-title">Изображение:</span>

              <img v-if="new_img === null" :src="'https://vrizion.ru/images/sales/' + item_obj.img" alt="" class="img-preview">
              <img v-if="new_img !== null" :src="new_img" alt="" class="img-preview">


              <div class="image-loader-block">
                <input accept="image/*" multiple type="file"
                       @change="previewImage">
                <svg viewBox="0 0 24 24" style="height: 32px; width: 32px;">
                  <path fill="currentColor" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z"></path>
                </svg>
              </div>
            </div>
            <div>
              <div>
                <span class="bl-title">Описание:</span>
                <textarea
                    :value="item_obj.desc"
                    @input="block.description = $event.target.value"></textarea>
              </div>

            </div>

          </div>
          <div class="btns-block">
            <div class="content-save" @click="saveContent(item_obj.id)" v-if="!blank">
              <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;">
                <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
              </svg>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SaleModal",
  components: {

  },
  data(){
    return{
      img_start: null,
      video_file: null,
      image_file: null,
      blank_image: null,
      blank_video: null,
      blank_image_name: '',
      blank_video_name: '',
      card_id: null,
      item: {
        title: "",
        desc: ""
      },

      block:{
        image: "",
        title: '',
        description: ''
      },
      file: "",
      label: "Выберите изображение",
      label2: "Выберите видео",
      categories: [],
      open: true,
      new_img: null,
      new_video: null
    }
  },
  props: {
    item_obj: {
      type: Object,
      required: true
    },
    games_categories: {
      type: Array,
      required: true
    },
    blank: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      this.new_img = URL.createObjectURL(file);
      this.block.image = file.name
      this.image_file = event.target.files[0];
    },
    saveContent(id){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_sale', {
            sale_id: id, block: this.block
          })
          .then(response => {
            this.$emit('update', true)
            console.log(response.data)
            this.modalClose()

          })
          .catch(() => {

          });
      if(this.image_file !== null){
        axios
            .post('https://vrizion.ru/server/sale_file_loader.php', {file: this.image_file}, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              this.$emit('update', true)
              console.log(response.data)

            })
            .catch(() => {

            });


      }

    },
    getCategories(){
      if(this.item_obj.category_id){
        axios
            .post('https://vrizion.ru/server/contentLoader.php?get_categories')
            .then(response => {
              this.categories = response.data

              console.log(response.data)

            })
            .catch(() => {

            });
      }
    },

    modalClose(){
      this.$emit('close', true)
      this.img_start = null
      this.open = false
      this.block.category_id = 0
      this.block.img = ""
      this.block.video = ""
      this.$props.blank = false
      this.blank_image = null
      this.blank_video = null
      this.blank_image_name = ''
      this.blank_video_name = ''
      this.new_img = null
      this.new_video = null
    },


  },
  mounted() {

    this.getCategories()
  },
  updated() {
    this.open = true
  }
}
</script>

<style scoped>
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0,0,0,.4);
}
.modal_block{
  padding-bottom: 3rem;
  width: 960px;
  background-color: white;
  margin: auto;
  border-radius: 4px;
  box-shadow: rgb(47, 37, 37) 4px 13px 20px 0px;
}
.modal-top{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 60px;
}
.modal-top svg{
  margin-right: 1rem;
  color: #bbb9c7;
  cursor: pointer;
  transition: 0.5s;
}
.modal-top svg:hover{
  color: white;
}
.modal-body{
  padding: 0 3rem;

}
.modal-body select{
  margin-bottom: 0.7rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  color: #303943;
  outline: none;
  cursor: pointer;
}
.modal-body input{
  width: 40%;
  margin-bottom: 0.7rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  color: #303943;
  outline: none;
}
.modal-body textarea{
  width: 580px;
  height: 368px;
  background-color: transparent;
  border: 1px solid #e1e1e1;
  padding: 0.5rem;
  font-size: 14px;
  color: #303943;
}
.modal-content-block{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.modal-content-block textarea{
  width: 100%;
  height: 280px;
}
.modal-left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-img{
  width: 241px;
  height: 370px;
}
.modal-right{
  margin: 0 3rem;
}
.modal-right-text{
  display: flex;
  align-items: center;
}
.modal-right-text svg{
  margin-left: 0.5rem;
}
.modal-right-block{
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  height: max-content;
  background-color: #f0f2f3;
}
.modal-right-block-row{
  display: block;
  justify-content: space-between;
  padding: 0 0 0.5rem 0;
}
.modal-right-block-row-desc{
  color: #696777;
  font-size: 12px;
}
.row-line{
  width: 100%;
  height: 1px;
  background-color: #3e3c48;
  margin: 1rem 0;
}
.bl-title{
  color: #303943;
  font-size: 16px;
  display: block;
  margin-bottom: 0.7rem;
}
.bl-desc{
  color: white;
  font-weight: 600;
  font-size: 14px;
}
.modal-input{
  padding: 0.2rem;
  outline: none;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #bbb9c7;
  color: white;
}
.bl-text{
  margin-right: 0.5rem;
  font-size: 13px;
  padding: 0.3rem 0.4rem;
  border-radius: 4px;
  color: #7bc377;
  background-color: rgb(123 195 119 / 10%);
}
.modal-left-img{
  width: 80%;
  height: 80%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.5s;
  cursor: zoom-in;
}

.modal-right-text{
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.modal-right-block-row input, textarea{
  display: block;
  width: 100%;
  background-color: #32303f5e;
  border: 0;
  outline: none;
  padding: 0.5rem;
  border-radius: 4px;
  color: white;
}

.image-loader-block{
  width: 100%;
  height: 60px;
  background-color: #f6f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dbe0e5;
  cursor: pointer;
  margin: 1rem 0;
  transition: 0.5s;
}
.image-loader-block:hover{
  background-color: #03e882;
  color: #059f59;
}

.image-loader-block svg{
  position: absolute;
}
.image-loader-block input[type=file]{
  position: inherit;
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.img-preview{
  width: 100%;
  height: 280px;
}
.video-preview{
  width: 100%;
  height: 370px;
}
#index-video{
  height: 100%;
  width: 100%;
  z-index: -100;
  right: 0;
  bottom: 0;
  background: no-repeat center center;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
}
.content-save{
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f6f7f8;
  width: -moz-max-content;
  width: max-content;
  color: #b9bdc1;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  transition: 0.5s;
}
.content-save:hover{
  background-color: #03e882;
  color: #059f59;
}
.content-delete{
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f6f7f8;
  width: -moz-max-content;
  width: max-content;
  color: #b9bdc1;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: 0.5s;
}
.content-delete:hover{
  background-color: #e8032d;
  color: #9f0521;
}
</style>