<template>
  <SaleModal v-show="modalView" @update="getSales" @close="closeModal" :item_obj="itemObj"></SaleModal>

  <div class="admin-page-title">Редактирование блока скидок</div>
  <div class="admin-page-block-desc">
    <label>Заголовок</label>
    <input type="text"
           v-bind:value="block.page_title"
           @input="block.page_title = $event.target.value"
    >
    <label>Описание</label>
    <textarea name="" id="" cols="20" rows="5"
              v-bind:value="block.page_desc"
              @input="block.page_desc = $event.target.value"></textarea>
    <div class="btn-save" @click="saveContent">Сохранить</div>
  </div>
  <div class="admin-page-block">
    <div class="admin-page-block-title">Настройка карточек скидок
      <svg viewBox="0 0 24 24" style="height: 24px; width: 24px;" @click="isSalesBlock = !isSalesBlock">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
      </svg>
    </div>
    <div class="admin-page-block-content" v-show="isSalesBlock" style="display: block;">
      <div class="sales-container">
        <div class="sale-card" v-for="sale in sales_cards" :key="sale">
          <img :src="'https://vrizion.ru/images/sales/' + sale.img" alt="" class="img-preview" @click="openModal(sale)">

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import SaleModal from "@/components/UI/SaleModal";

export default {
  name: "AdminSales",
  components: {
    SaleModal
  },
  data(){
    return{
      image_file: null,

      new_img: '',
      label: "Выберите изображение",
      block_id: 2,
      block: {
        page_title: "",
        page_desc: ""
      },
      sale: {
        title: '',
        description: '',
        image: ''
      },
      isSalesBlock: false,
      sales_cards: [],
      modalView: false,
      itemObj: []

    }
  },
  methods:{
    openModal(obj){
      this.modalView = true
      this.itemObj = obj

    },
    closeModal(){
      this.modalView = false
    },
    getText(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_block_text', {
            block_id: this.block_id
          })
          .then(response => {
            this.block.page_title = response.data[0].title
            this.block.page_desc = response.data[0].description
            // console.log(response.data)

          })
          .catch(() => {

          });
    },

    saveContent(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?save_page_texts', {
            block: this.block, page_id: this.block_id
          })
          .then(response => {
            this.getText()
            console.log(response)

          })
          .catch(() => {

          });
    },

    getSales(){
      axios
          .post('https://vrizion.ru/server/contentLoader.php?get_sales_admin')
          .then(response => {
            this.sales_cards = response.data
            console.log(response.data)

          })
          .catch(() => {

          });
    },
  },
  mounted() {
    this.getText()
    this.getSales()

  }
}
</script>

<style scoped>
.sales-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
@media (max-width: 600px) {
  .sales-container { grid-template-columns: repeat(1, 1fr); }
}
@media (min-width: 600px) {
  .sales-container { grid-template-columns: repeat(2, 1fr); }
}
@media (min-width: 900px) {
  .sales-container { grid-template-columns: repeat(3, 1fr); }
}
@media (min-width: 1280px) {
  .sales-container { grid-template-columns: repeat(4, 1fr); }
}
.sale-card{
  width: 100%;
  height: max-content;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
}
.sale-card input{
  width: 100%;
  height: 40px;
  border: 1px solid #8a93a230;
  background-color: transparent;
  border-radius: 4px;
  padding: 0.5rem;
  color: #4c5967;
  outline: none;
  margin-bottom: 1rem;
  font-size: 14px;
}
.sale-card textarea{
  width: 100%;
  min-height: 200px;
  border: 1px solid #8a93a230;
  background-color: transparent;
  border-radius: 4px;
  padding: 0.5rem;
  color: #4c5967;
  outline: none;
  font-size: 14px;
}
.img-preview{
  width: 100%;
  height: 60%;
}

.image-loader-block svg{
  position: absolute;
}
.image-loader-block input[type=file]{
  position: inherit;
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>